<template>
<div>test</div>
</template>

<script>


export default {
    name: "forbidden",
    data() {

    },
    methods: {

    },
    mounted() {

    }
}
</script>

<style scoped></style>